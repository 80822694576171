<template>
  <div class="right-bar">
    <div class="right-bar__back" @click="changeToggleRegistration(false)"/>
    <div class="right-bar__content">
      <div class="right-bar__close" @click="changeToggleRegistration(false)"></div>`
      <div class="right-bar__title col-md-10">
        Pharmacy Registration
      </div>
      <div class="right-bar__form col-md-10">
        <div class="right-bar__form-block row">
          <div class="right-bar__form-title col-md-12">
            Pharmacy Details
          </div>
          <label class="input-block col-md-4 col-sm-12">
            <input type="text"  v-model="$v.form.pharmacy_ncpdp.$model" v-mask="'######'"><span/>
            <span class="input-block__name" :class="form.pharmacy_ncpdp.length ?'active' : null" >Pharmacy NCPDP#*</span>
              <small class="input-block__error" v-if="$v.form.pharmacy_ncpdp.$dirty && !$v.form.pharmacy_ncpdp.minLength || !$v.form.pharmacy_ncpdp.maxLength ">pharmacy ncpdp must be 6 digits.</small>
          </label>
          <label class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.pharmacy_npi.$model"><span/>
            <span class="input-block__name" :class="form.pharmacy_npi.length ?'active' : null" >Pharmacy NPI#*</span>
            <small class="input-block__error" v-if="$v.form.pharmacy_npi.$dirty && !$v.form.pharmacy_npi.required">pharmacy npi is required</small>
          </label>
          <label class="file-block col-xs-4">
            <input type="file" @change="uploadLogo" accept="image/png, image/jpeg">
            <img class="file-block__img" :src="logoUrl" alt="userLogo" v-if="logoUrl">
            <span>Add Logo Pharmace</span>
            <small class="input-block__error ml-5" v-if="$v.form.dea_form_id.$dirty && !$v.form.dea_form_id.required">logo is required</small>
            <small class="input-block__error ml-5" v-if="errorLogoMaxSize">max file size 5mb</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.dea.$model"><span/>
            <span class="input-block__name" :class="form.dea.length ?'active' : null" >Dea#*</span>
            <small class="input-block__error" v-if="$v.form.dea.$dirty && !$v.form.dea.required">dea is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.dea_confirmation.$model"><span/>
            <span class="input-block__name" :class="form.dea_confirmation.length ?'active' : null" >Verify Your Dea Number#*</span>
            <small class="input-block__error" v-if="$v.form.dea_confirmation.$dirty && $v.form.dea_confirmation.$error">dea confirmation is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.pharmacy_state_license_number.$model"><span/>
            <span class="input-block__name" :class="form.pharmacy_state_license_number.length ?'active' : null" >Pharmacy State License#*</span>
            <small class="input-block__error" v-if="$v.form.pharmacy_state_license_number.$dirty && !$v.form.pharmacy_state_license_number.required">pharmacya state license_number is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="form.federal_tax_id"><span/>
            <span class="input-block__name" :class="form.federal_tax_id.length ?'active' : null" >Federal Tax ID#</span>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.pharmacy_legal_name.$model"><span/>
            <span class="input-block__name" :class="form.pharmacy_legal_name.length ?'active' : null" >Pharmacy Legal Name/ DBA*</span>
            <small class="input-block__error" v-if="$v.form.pharmacy_legal_name.$dirty && !$v.form.pharmacy_legal_name.required">pharmacy legal name is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.store.$model"><span/>
            <span class="input-block__name" :class="form.store.length ?'active' : null" >Store#*</span>
            <small class="input-block__error" v-if="$v.form.store.$dirty && !$v.form.store.required">store is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="$v.form.phone.$model" v-mask="'+#############'" @input="validatePhoneForm(form.phone, 'phoneRegexError')"><span/>
            <span class="input-block__name" :class="form.phone.length ?'active' : null" >Phone#*</span>
            <small class="input-block__error" v-if="$v.form.phone.$dirty && !$v.form.phone.minLength" >The phone must be at least 6 characters</small>
            <small class="input-block__error" v-if="$v.form.phone.$dirty && !$v.form.phone.maxLength" >The phone must be at biggest 14 characters </small>
            <small class="input-block__error" v-if="$v.form.phone.$dirty && this.phoneRegexError && $v.form.phone.minLength && $v.form.phone.maxLength">The phone must by start with + country code from 1</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.mobile_phone.$model" v-mask="'+#############'"  @input="validatePhoneForm(form.mobile_phone, 'mobileRegexError')"><span/>
            <span class="input-block__name" :class="form.mobile_phone.length ?'active' : null" >Mobile phone#*</span>
            <small class="input-block__error" v-if="$v.form.mobile_phone.$dirty && !$v.form.mobile_phone.minLength" >The Mobile phone must be at least 6 characters</small>
            <small class="input-block__error" v-if="$v.form.mobile_phone.$dirty && !$v.form.mobile_phone.maxLength" >The Mobile phone must be at biggest 14 characters </small>
            <small class="input-block__error" v-if="$v.form.mobile_phone.$dirty && this.mobileRegexError && $v.form.mobile_phone.minLength && $v.form.mobile_phone.maxLength">The Mobile phone must by start with + country code from 1</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.fax.$model" v-mask="'+#############'"  @input="validatePhoneForm(form.fax, 'faxRegexError')"><span/>
            <span class="input-block__name" :class="form.fax.length ?'active' : null">Fax#*</span>
            <small class="input-block__error" v-if="$v.form.fax.$dirty && !$v.form.fax.minLength" >The fax must be at least 6 characters</small>
            <small class="input-block__error" v-if="$v.form.fax.$dirty && !$v.form.fax.maxLength" >The fax must be at biggest 14 characters </small>
            <small class="input-block__error" v-if="$v.form.fax.$dirty && this.faxRegexError && $v.form.fax.minLength && $v.form.fax.maxLength">The fax must by start with + country code from 1</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="form.website_link"><span/>
            <span class="input-block__name" :class="form.website_link.length ?'active' : null" >Web site Link</span>
          </label>
          <label class="select-block col-md-6 col-sm-12" v-if="statesList">
            <v-select
                multiple
                v-model="form.pharmacy_states"
                :options="statesList"
                :reduce="(i) => i.id"
                label="title"
                placeholder="List of State"
            />
            <span class="bottom"/>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="text" v-model="$v.form.system_software_name.$model"><span/>
            <span class="input-block__name" :class="form.system_software_name.length ?'active' : null" >Pharmacy Managment System Software Name*</span>
            <small class="input-block__error" v-if="$v.form.system_software_name.$dirty && !$v.form.system_software_name.required">system software name is required</small>
          </label>
        </div>
        <div class="right-bar__form-block row">
          <div class="right-bar__form-title col-md-12">
            Physical Address
          </div>
          <label class="input-block col-md-12">
              <input type="text" v-model="$v.form.physical_address.$model"><span/>
            <span class="input-block__name" :class="form.physical_address.length ?'active' : null" >Physical Address#*</span>
            <small class="input-block__error" v-if="$v.form.physical_address.$dirty && !$v.form.physical_address.required">address is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="$v.form.physical_city.$model"><span/>
            <span class="input-block__name" :class="form.physical_city.length ?'active' : null" >City#*</span>
            <small class="input-block__error" v-if="$v.form.physical_city.$dirty && !$v.form.physical_city.required">city is required</small>
          </label>
          <label class="select-block col-md-4 col-sm-12" v-if="statesList">
            <v-select
                v-model="form.physical_state_id"
                :options="statesList"
                :reduce="(i) => i.id"
                label="title"
                placeholder="State"
            /><span class="bottom"/>
            <small class="input-block__error" v-if="$v.form.physical_state_id.$dirty && $v.form.physical_state_id.$error">state is required</small>
          </label>
          <label class="input-block col-md-2 col-sm-12">
              <input type="text" v-model="$v.form.physical_zip_code.$model"  v-mask="'########'"><span/>
            <span class="input-block__name" :class="form.physical_zip_code.length ?'active' : null" >Zip Code#*</span>
            <small class="input-block__error" v-if="$v.form.physical_zip_code.$dirty && !$v.form.physical_zip_code.required">zip code is required</small>
          </label>
          <label class="checkbox-block col-md-12">
              <input
                  type="checkbox"
                  class="checkbox"
                  @input="mailingAsPhysical()"
                  v-model="form.mailing_as_physical">
              <span>My Phisycal and Mailing Address is same</span>
          </label>
        </div>
        <div class="right-bar__form-block row" v-if="!form.mailing_as_physical">
          <div class="right-bar__form-title col-md-12">
            Mailing Address
          </div>
          <label class="input-block col-md-12">
              <input type="text" v-model="$v.form.mailing_address.$model"><span/>
            <span class="input-block__name" :class="form.mailing_address.length ?'active' : null" >Mailing Address#*</span>
            <small class="input-block__error" v-if="$v.form.mailing_address.$dirty && !$v.form.mailing_address.required">address is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="$v.form.mailing_city.$model"><span/>
            <span class="input-block__name" :class="form.mailing_city.length ?'active' : null" >City#*</span>
            <small class="input-block__error" v-if="$v.form.mailing_city.$dirty && !$v.form.mailing_city.required">city is required</small>
          </label>
          <label class="select-block col-md-4 col-sm-12" v-if="statesList">
            <v-select
                v-model="$v.form.mailing_state_id.$model"
                :options="statesList"
                :reduce="(i) => i.id"
                label="title"
                placeholder="State"
            /><span class="bottom"/>
            <small class="input-block__error" v-if="$v.form.mailing_state_id.$dirty && $v.form.mailing_state_id.$error">state is required</small>
          </label>
          <label class="input-block col-md-2 col-sm-12">
            <input type="text" v-model="$v.form.mailing_zip_code.$model"  v-mask="'########'"><span/>
            <span class="input-block__name" :class="form.mailing_zip_code.length ?'active' : null" >Zip Code#*</span>
            <small class="input-block__error" v-if="$v.form.mailing_zip_code.$dirty && !$v.form.mailing_zip_code.required">zip code is required</small>
          </label>
        </div>
        <div class="right-bar__form-block row">
          <div class="right-bar__form-title col-md-12">
            Other Details
          </div>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="$v.form.manager_contact_name.$model"><span/>
            <span class="input-block__name" :class="form.manager_contact_name.length ?'active' : null" >Pharmacy Manager (PIC)/Contact Name*</span>
            <small class="input-block__error" v-if="$v.form.manager_contact_name.$dirty && !$v.form.manager_contact_name.required">manager contact name is required</small>
          </label>
          <label class="input-block col-md-6 col-sm-12">
              <input type="text" v-model="$v.form.contract_manager.$model"><span/>
            <span class="input-block__name" :class="form.contract_manager.length ?'active' : null" >Contract Manager*</span>
            <small class="input-block__error" v-if="$v.form.contract_manager.$dirty && !$v.form.contract_manager.required">contract manager is required</small>
          </label>
          <label class="select-block col-md-6 col-sm-12" v-if="businessTypeList">
            <v-select
                v-model="form.business_types"
                :options="businessTypeList"
                :reduce="(i) => i.id"
                label="title"
                placeholder="Select One or More that match your business"
                multiple
            /><span class="bottom"/>
          </label>
          <label class="radio-block col-md-6 col-sm-12">
            <p>
              340B Status*
            </p>
            <label>
              <input type="radio" name="340BStatus" class="radio" value="1" v-model="form.b340_status">
              <span>YES</span>
            </label>
            <label>
              <input type="radio" name="340BStatus" class="radio" value="0" v-model="form.b340_status">
              <span>No</span>
            </label>
          </label>
          <label class="input-block col-md-6 col-sm-12">
            <input type="email" v-model="$v.form.email.$model"><span/>
            <span class="input-block__name" :class="form.email.length ?'active' : null" >E-mail*</span>
            <small class="input-block__error" v-if="$v.form.email.$dirty && $v.form.email.$error">email must be valid</small>
          </label>
          <label class="input-block col-md-3 col-sm-12">
            <input type="password" v-model="$v.form.password.$model" @input="validatePasswordForm()"><span/>
            <span class="input-block__name" :class="form.password.length ? 'active' : null" >password*</span>
            <small class="input-block__error" v-if="$v.form.password.$dirty && !$v.form.password.minLength">The password must be at least 6 characters</small>
            <small class="input-block__error" v-if="$v.form.password.$dirty && !$v.form.password.maxLength">The password must be at biggest 40 characters</small>
            <small class="input-block__error" v-if="$v.form.password.$dirty && this.passwordRegexError && $v.form.password.minLength && $v.form.password.maxLength">The password must by including uppercase and lowercase letters and digits</small>
          </label>
          <label class="input-block col-md-3 col-sm-12">
            <input type="password" v-model="$v.form.password_confirmation.$model"><span/>
            <span class="input-block__name" :class="form.password_confirmation.length ?'active' : null" >Confirm Password*</span>
            <small class="input-block__error" v-if="$v.form.password_confirmation.$dirty && $v.form.password_confirmation.$error">password confirmation is required</small>
          </label>
        </div>
        <div class="right-bar__form-block row">
          <div class="right-bar__form-title col-md-12">
            Add documents
          </div>
          <label class="document-block col-md-12">
            <inline-svg :src="require(`@/assets/images/svg/add-document-ic.svg`)"/>
            <p class="document-block__title">Dea Form</p>
            <p class="document-block__input">
              <input type="file" @change="uploadDeaForm"
                     accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain,">
              <span v-if="dea_form.length">{{ dea_form }}</span>
              <span v-else>Browse file</span>
            </p>
            <small class="input-block__error ml-5" v-if="error_dea_form_size">max file size 20mb</small>
          </label>
          <label class="document-block col-md-12">
            <inline-svg :src="require(`@/assets/images/svg/add-document-ic.svg`)"/>
            <p class="document-block__title">Pharmacy Lisence</p>
            <p class="document-block__input">
              <input type="file" @change="uploadPharmacyLisence"
               accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain,">
              <span v-if="pharmacy_license.length">{{ pharmacy_license }}</span>
              <span v-else>Browse file</span>
            </p>
            <small class="input-block__error ml-5" v-if="error_pharmacy_license_size">max file size 20mb</small>
            <small class="input-block__error" v-if="$v.form.pharmacy_license_id.$dirty && $v.form.pharmacy_license_id.$error">file is required</small>
          </label>
          <label class="document-block col-md-12">
            <inline-svg :src="require(`@/assets/images/svg/add-document-ic.svg`)"/>
            <p class="document-block__title">Drivers Lisence</p>
            <p class="document-block__input">
              <input type="file" @change="uploadDriversLisence"
                     accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain,">
              <span v-if="drivers_license.length">{{ drivers_license }}</span>
              <span v-else>Browse file</span>
            </p>
            <small class="input-block__error ml-5" v-if="error_drivers_license_size">max file size 20mb</small>
            <small class="input-block__error" v-if="$v.form.pharmacy_license_id.$dirty && $v.form.pharmacy_license_id.$error">file is required</small>
          </label>
          <label class="document-block col-md-12">
            <inline-svg :src="require(`@/assets/images/svg/add-document-ic.svg`)"/>
            <p class="document-block__title">Voided check</p>
            <p class="document-block__input">
              <input type="file" @change="uploadVoidedCheck"
                     accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain,">
              <span v-if="voided_check.length">{{ voided_check }}</span>
              <span v-else>Browse file</span>
            </p>
            <small class="input-block__error ml-5" v-if="error_voided_check_size">max file size 20mb</small>
            <small class="input-block__error" v-if="$v.form.voided_check_id.$dirty && $v.form.voided_check_id.$error">file is required</small>
          </label>
        </div>
        <div class="right-bar__form-block row" v-if="questionsList">
          <div class="right-bar__form-title col-md-12">
            Review the following statements:
          </div>
          <div class="col-md-12" v-for="(itemQuestions, index) in form.questions " :key="index">
           <label class="radio-block vertical" v-if="itemQuestions.parent || questionParentTrue(itemQuestions)">
            <p>
              {{ itemQuestions.question }}
            </p>
            <label>
              <input type="radio" :name="itemQuestions.id+itemQuestions.question" class="radio" value="1" v-model="itemQuestions.answer">
              <span>YES</span>
            </label>
            <label>
              <input type="radio" :name="itemQuestions.id+itemQuestions.question" class="radio" value="0" v-model="itemQuestions.answer">
              <span>No</span>
            </label>
          </label>
          </div>
        </div>
        <div class="right-bar__form-block row">
          <div class="col-md-12 d-flex align-center">
            <small class="input-block__error ml-2" v-if="$v.form.$dirty && $v.form.$error">fill in all the fields correctly</small>

            <button class="btn grey mr-3"
              @click="registration()"
            >
              Register
            </button>
            <span>If you have an account - <a href="#" class="link" @click.prevent="openLogin()">LOG IN</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { required, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

export default {
  data(){
    return{
      passwordRegexError: false,
      phoneRegexError: false,
      mobileRegexError: false,
      faxRegexError: false,
      showQuestion: false,
      errorLogoMaxSize: false,
      error_dea_form_size: false,
      error_pharmacy_license_size: false,
      error_drivers_license_size: false,
      error_voided_check_size: false,
      dea_form:'',
      pharmacy_license:'',
      drivers_license:'',
      voided_check:'',
      form:{
        email:'',
        password:'',
        password_confirmation: '',
        pharmacy_ncpdp:'',
        pharmacy_npi:'',
        dea:'',
        dea_confirmation:'',
        pharmacy_state_license_number:'',
        federal_tax_id:'',
        pharmacy_legal_name:'',
        store:'',
        phone:'',
        mobile_phone:'',
        fax:'',
        website_link:'',
        pharmacy_states:[],
        system_software_name:'',
        manager_contact_name:'',
        contract_manager:'',
        business_types:[],
        b340_status:0,
        logo_id:'',
        dea_form_id:'',
        pharmacy_license_id:'',
        drivers_license_id:'',
        voided_check_id:'',
        'g-recaptcha-response':'',
        physical_address:'',
        physical_city:'',
        physical_zip_code:'',
        physical_state_id:null,
        mailing_as_physical:'',
        mailing_address:'',
        mailing_city:'',
        mailing_zip_code:'',
        mailing_state_id:'',
        questions:[],
      },
      logoUrl: null,
    }
  },
  validations: {
    form: {
      email:{
        email,
        required
      },
      password:{
        required,
        minLength: minLength(6),
        maxLength: maxLength(40)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')

      },
      pharmacy_ncpdp:{
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      pharmacy_npi:{
        required
      },
      dea:{
        required
      },
      dea_confirmation:{
        required,
        sameAsPassword: sameAs('dea')
      },
      pharmacy_state_license_number:{
        required
      },
      pharmacy_legal_name:{
        required
      },
      store:{
        required
      },
      phone:{
        required,
        minLength: minLength(7),
        maxLength: maxLength(14),
      },
      mobile_phone:{
        required,
        minLength: minLength(7),
        maxLength: maxLength(14),
      },
      fax:{
        required,
        minLength: minLength(7),
        maxLength: maxLength(14),
      },
      system_software_name:{
        required
      },
      manager_contact_name:{
        required
      },
      contract_manager:{
        required
      },
      logo_id:{
        required
      },
      dea_form_id:{
        required
      },
      pharmacy_license_id:{
        required
      },
      drivers_license_id:{
        required
      },
      voided_check_id:{
        required
      },
      physical_address:{
        required
      },
      physical_city:{
        required
      },
      physical_zip_code:{
        required
      },
      physical_state_id:{
        required,
        minLength: minLength(1)
      },
      mailing_address:{
        required
      },
      mailing_city:{
        required
      },
      mailing_zip_code:{
        required
      },
      mailing_state_id:{
        required,
        minLength: minLength(1)
      },
    }
  },
  computed:{
    ...mapGetters({
      statesList:'auth/statesList',
      businessTypeList:'auth/businessTypeList',
      questionsList:'auth/questionsList',
    })
  },
  created() {
    this.getStatesList();
    this.getBusinessTypeList();
    this.getRegistrationQuestions().then(()=>{
      this.addQuestionsToForm();
    });
  },
  methods:{
    registration(){
        this.$v.$touch();
        if(
            !this.$v.form.$error &&
            this.validatePhoneForm(this.form.phone, 'phoneRegexError') &&
            this.validatePhoneForm(this.form.mobile_phone, 'mobileRegexError') &&
            this.validatePhoneForm(this.form.fax, 'faxRegexError') &&
            this.validatePasswordForm()
        ){
          this.recaptcha().then(()=>{
            this.sendRegistration(this.form).then(res=>{
              if(res.status){
                this.toggleAlertPopup({
                  status: true,
                  content: {
                    title: 'Success!',
                    text: 'You have successfully registered. Wait for the confirmation letter.',
                    type: "success"
                  }
                });
              }else{
                this.toggleAlertPopup({
                  status: true,
                  content: {
                    title:'Error',
                    text: res.data.errors,
                    type:"Error!"
                  }
                });
              }
            })
          })
        }
    },
    validatePhoneForm(value, valid){
      if(value.match('^\\+[1-9]{1}[0-9]{5,14}$')){
        this[valid] = false;
        return true;
      }else{
        this[valid] = true;
        return false;
      }
    },
    validatePasswordForm(){
      if(this.form.password.match('^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9]).*$')){
        this.passwordRegexError = false;
        return true
      }else{
        this.passwordRegexError = true;
        return false
      }
    },
    openLogin(){
      this.changeToggleRegistration(false);
      this.changeToggleLogin(true);
    },
    questionParentTrue(item){
      if(item.parentId){
        const questionParen = this.form.questions.filter(questionItem=> questionItem.id === item.parentId)[0];
        return Boolean(questionParen.answer);
      }
    },
    uploadLogo(e){
      if(e.target && e.target.files[0]) {
        const file = e.target.files[0];
        const kbSize = file.size / 1000;
        const mbSize = kbSize / 1000;
        if (mbSize < 5) {
          this.logoUrl = URL.createObjectURL(file);
          this.uploadFile(file).then(res => {
            this.errorLogoMaxSize = false;
            this.form.logo_id = res.data.file_id;
          })
        } else {
          this.errorLogoMaxSize = true;
        }
      }
    },
    uploadDeaForm(e){
      this.uploadDocs(e, 'dea_form')
    },
    uploadPharmacyLisence(e){
      this.uploadDocs(e, 'pharmacy_license')
    },
    uploadDriversLisence(e){
      this.uploadDocs(e, 'drivers_license')
    },
    uploadVoidedCheck(e){
      this.uploadDocs(e, 'voided_check')
    },
    uploadDocs(e, type){
      if(e.target && e.target.files[0]){
        const file = e.target.files[0];
        const kbSize = file.size / 1000;
        const mbSize = kbSize / 1000;
        if(mbSize < 20){
          this[type] = file.name;
          this[`error_${type}_size`] = false;
          this.uploadFile(file).then(res =>{
            this.form[`${type}_id`] = res.data.file_id;
          })
        }else{
          this[`error_${type}_size`] = true;
        }
      }
    },
    addQuestionsToForm(){
      this.questionsList.map(itemQuestion =>{
        this.form.questions.push({
          parent: true,
          id: itemQuestion.id,
          question: itemQuestion.question,
          answer: 0,
        })
        if(itemQuestion.sub_questions.length){
          itemQuestion.sub_questions.map(itemSubQuestion => {
            this.form.questions.push({
              parent: false,
              id: itemSubQuestion.id,
              parentId: itemQuestion.id,
              question: itemSubQuestion.question,
              answer: 0,
            })
          })
        }
      })
    },
    mailingAsPhysical(){
      if(!this.form.mailing_as_physical){
        this.form.mailing_address = this.form.physical_address;
        this.form.mailing_city = this.form.physical_city;
        this.form.mailing_zip_code = this.form.physical_zip_code;
        this.form.mailing_state_id = this.form.physical_state_id;
      }else{
        this.form.mailing_address = '';
        this.form.mailing_city = '';
        this.form.mailing_zip_code = '';
        this.form.mailing_state_id = null;
      }
    },
      async recaptcha() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        this.form['g-recaptcha-response'] = await this.$recaptcha('login')
        // Do stuff with the received token.

    },
    ...mapActions({
      getStatesList:'auth/getStatesList',
      getBusinessTypeList:'auth/getBusinessTypeList',
      getRegistrationQuestions:'auth/getRegistrationQuestions',
      sendRegistration:'auth/sendRegistration',
      uploadFile:'general/uploadFile',
    }),
    ...mapMutations({
      changeToggleRegistration: 'general/changeToggleRegistration',
      changeToggleLogin: 'general/changeToggleLogin',
      toggleAlertPopup: 'general/toggleAlertPopup'
    })
  },
}
</script>
